<template>
    <div>
        <a v-if="rojo==true" class="nav-link" :class="{active: activo}" data-toggle="row" id="tab-pages-charts-inline-html"
            href="#panel-pages-charts-inline-html" role="tab" aria-controls="panel-pages-charts-inline-html"
            aria-selected="true" @click.prevent="tabSelected" style="background:rgba(255, 180, 180, 0.5) !important"><i :class="icono"></i>&nbsp;{{ $t(resultado) }}</a>
        <a v-if="rojo==false" class="nav-link" :class="{ active: activo }" data-toggle="row" id="tab-pages-charts-inline-html"
            href="#panel-pages-charts-inline-html" role="tab" aria-controls="panel-pages-charts-inline-html"
            aria-selected="true" @click.prevent="tabSelected" ><i :class="icono"></i>&nbsp;{{ $t(resultado) }}</a>
        <slot />
    </div>
</template>

<script>
export default {
    props: ['nombre', 'activo', 'titulo','ruta'],
    methods: {
        tabSelected() {
            this.$emit('tabSelected');
        },
        seleccionaricono() {
            if(this.ruta.path){
            if (this.ruta.path.includes('companias')) {
                this.icono = "fas fa-briefcase";
            }
            if (this.ruta.path.includes('DocumentacionRequerida')) {
                this.icono = "fas fa-folder-open";
            }
            if (this.ruta.path.includes('GestionDocumentos')) {
                this.icono = "fas fa-paste";
            }
            if (this.ruta.path.includes('GestionCampos')) {
                this.icono = "fas fa-poll-h";
            }
            if (this.ruta.path.includes('clientes')) {
                this.icono = "fas fa-user-friends";
            }
            if (this.ruta.path.includes('comerciales')) {
                this.icono = "fas fa-user-tag";
            }
            if (this.ruta.path.includes('especialidades')) {
                this.icono = "fas fa-tags";
            }
            if (this.ruta.path.includes('estadospendientes')) {
                this.icono = "fas fa-highlighter";
            }
            if (this.ruta.path.includes('SMS')) {
                this.icono = "fas fa-sms";
            }
            if (this.ruta.path.includes('configuracion')) {
                this.icono = "fas fa-cogs";
            }
            if (this.ruta.path.includes('mensajespredefinidos')) {
                this.icono = "fas fa-envelope-open-text";         
            }
            if (this.ruta.path==('/operarios')) {
                this.icono = "fas fa-hard-hat";
            }
            if (this.ruta.path==('/facturacionOperarios')) {
                this.icono = "fas fa-hard-hat";
            }
            if (this.ruta.path==('/facturacionAlbaranes')) {
                this.icono = "fas fa-file-invoice";
            }
            if (this.ruta.path.includes('pedidos')) {
                this.icono = "fas fa-boxes";
            }
            if (this.ruta.path.includes('peritos')) {
                this.icono = "fas fa-user-tie";
            }
            if (this.ruta.path.includes('proveedores')) {
                this.icono = "fas fa-truck-loading";
            }
            if (this.ruta.path.includes('tiposdanyo')) {
                this.icono = "fas fa-clipboard-list";
            }
            if (this.ruta.path.includes('usuarios')) {
                this.icono = "fas fa-address-card";
            }
            if (this.ruta.path.includes('equiposMarcas')) {
                this.icono = "fas fa-tv";
            }
            if (this.ruta.path.includes('gestorTipos')) {
                this.icono = "fas fa-th-list";
            }
            if (this.ruta.path.includes('Eventos')) {
                this.icono = "fas fa-project-diagram";
            }
            if (this.ruta.path==('/coberturasoperarios')) {
                this.icono = "fas fa-draw-polygon";
            }
            if (this.ruta.path.includes('festivos')) {
                this.icono = "fa fa-calendar";
            }
            if (this.ruta.path=='/planning') {
                this.icono = "fas fa-calendar-alt";
            }
            if (this.ruta.path=='/planningGeografico') {
                this.icono = "fas fa-map-marked-alt";
            }
            if (this.ruta.path.includes('escritorio')) {
                this.icono = "nav-icon fas fa-home";
            }
            if (this.ruta.path.includes('chat')) {
                this.icono = "fab fa-weixin";
            }
            if (this.ruta.path.includes('empresas')) {
                this.icono = "fas fa-city";
            }
            if (this.ruta.path.includes('empresaprincipal')) {
                this.icono = "fas fa-building";
            }
            if (this.ruta.path.includes('bancos')) {
                this.icono = "fas fa-university";
            }
            
            if (this.ruta.path.includes('correos')) {
                this.icono = "fas fa-envelope";
            } 
            if (this.ruta.path.includes('lineasfacturacion')) {
                this.icono = "fas fa-file-export";
            }
            if (this.ruta.path.includes('lineasfacturacion')) {
                this.icono = "fas fa-file-export";
            }
            if (this.ruta.path.includes('condiciones')) {
                this.icono = "fas fa-list-ol";
            }
            if (this.ruta.path.includes('servicios')) {
                this.icono = "fas fa-paste";
            }
            if(this.ruta.path.includes('ste')){
                this.icono = "fas fa-house-user";
            }
        }     
        }
       
    },
    mounted() {
        window.addEventListener('guardadoestadopendiente', () => {
            console.log('entraguardado');
            if (this.ruta.path.startsWith('/servicios/')) {
                let idruta = this.ruta.path.split('/', 3)[2];
                const permitir = localStorage.getItem('permitir' + idruta);
                const permitirtipodanyo = localStorage.getItem('permitirtipodanyo' + idruta);
                const permitirfechaespera = localStorage.getItem('permitirfechaespera' + idruta);
                if (permitir == "true" && permitirtipodanyo=="true" && permitirfechaespera=="true") {
                    this.rojo = false;
                }
            }

        });
        window.addEventListener('guardadotipodanyo', () => {
            if (this.ruta.path.startsWith('/servicios/')) {
                let idruta = this.ruta.path.split('/', 3)[2];
                const permitir = localStorage.getItem('permitir' + idruta);
                const permitirtipodanyo = localStorage.getItem('permitirtipodanyo' + idruta);
                const permitirfechaespera = localStorage.getItem('permitirfechaespera' + idruta);
                if (permitir == "true" && permitirtipodanyo == "true" && permitirfechaespera == "true") {

                    this.rojo = false;
                }
            }

        });
        window.addEventListener('guardadofechaespera', () => {
            if (this.ruta.path.startsWith('/servicios/')) {
                let idruta = this.ruta.path.split('/', 3)[2];
                const permitir = localStorage.getItem('permitir' + idruta);
                const permitirtipodanyo = localStorage.getItem('permitirtipodanyo' + idruta);
                const permitirfechaespera = localStorage.getItem('permitirfechaespera' + idruta);

                if (permitir == "true" && permitirtipodanyo == "true" && permitirfechaespera == "true") {

                    this.rojo = false;
                }
            }

        });
       // this.resultado = 'general.' + this.titulo;
        this.resultado = this.titulo;
        if(this.ruta.path){
        if (this.ruta.path.startsWith('/servicios/')) {
            let idruta = this.ruta.path.split('/', 3)[2];
            const permitir = localStorage.getItem('permitir' + idruta);
            const permitirtipodanyo = localStorage.getItem('permitirtipodanyo' + idruta);
            const permitirfechaespera = localStorage.getItem('permitirfechaespera' + idruta);

            if (permitir == "false" || permitirtipodanyo == "false" || permitirfechaespera == "false") {
                
                this.rojo = true;
            }
        }
        }

    },
    beforeMount() {
        this.seleccionaricono();
        
    },
    watch: {
        nombre() {

            this.resultado = this.titulo;
        },
        tabSelected() {
            this.resultado = this.titulo;
           
        },
        activo() {
            this.resultado = this.titulo;
            if (this.ruta.path.startsWith('/servicios/')) {
                let idruta = this.ruta.path.split('/', 3)[2];
                console.log('entraactivo');
                const permitir = localStorage.getItem('permitir' + idruta);
                const permitirtipodanyo = localStorage.getItem('permitirtipodanyo' + idruta);
                const permitirfechaespera = localStorage.getItem('permitirfechaespera' + idruta);
                if (permitir == "false" || permitirtipodanyo == "false" || permitirfechaespera == "false") {
                    this.rojo = true;
                }
            }
                
        },
        titulo() {

            this.resultado = this.titulo;
            

        }
    },
    data() {
        return {
            resultado: '',
            title: '',
            rojo: false,
            icono:''
        }
    },
    
}
</script>