<template>
    <div>
        <a href="#" class="btn-iframe-close" data-widget="iframe-close" data-type="only-this" v-show="mostrar">
            <i class="fas fa-times"></i>
        </a>
    </div>
</template>

<script>
export default {
    props: ['mostrar'],
 
}
</script>

<style scoped>
.btn-iframe-close {
    color: #dc3545;
    position: absolute;
    line-height: 1;
    right: 0.125rem;
    top: 0.125rem;
    z-index: 10;
}
</style>